import * as React from 'react';
import styles from './Footer.module.scss';
import logo from '../../../images/inn-logo-lg.png';

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.footer_logo}>
        <img src={logo} alt={'logo'} />
      </div>
      <div className={styles.footer_copy}>
        <div className={styles.footer_copy__copyright}>
          © 2017 Incarceration Nations Network. All Rights Reserved.
        </div>
      </div>
    </footer>
  );
};
export default Footer;
