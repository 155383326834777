import React from 'react';
import NavSubItem from '../NavSubItem/NavSubItem';
import styles from '../NavSubItems/NavSubItems.module.scss';

const BluePrintSubItems = () => {
  return (
    <ul className={styles.navSubItems}>
      <NavSubItem link={'/blueprint-fr'} name={'French'} />

      <NavSubItem link={'/blueprint-sp'} name={'Spanish'} />
      <NavSubItem link={'/blueprint-port'} name={'Portuguese'} />

      <NavSubItem link={'/blueprint-ar'} name={'Arabic'} />
    </ul>
  );
};

export default BluePrintSubItems;
