import React from 'react';

import NavItems from '../Nav/NavItems/NavItems';

import styles from './Header.module.scss';
import ResponsiveLayout from '../ResponsiveLayout/ResponsiveLayout';
import MobileNav from '../Nav/MobileNav/MobileNav';
import { Link } from 'gatsby';

const Header = ({ headerHidden }: { headerHidden?: boolean }) => {
  return (
    <header
      className={
        styles.header + ' ' + (headerHidden ? styles.headerHidden : '')
      }
    >
      <div className={styles.header_layout}>
        <Link to={'/'} className={styles.header_layout__logoLayout}>
          <div className={styles.header_layout__logoLayout__logo} />
        </Link>
        {/* <a
        className={styles.header_layout__logoLayout}
        href="https://www.incarcerationnationsnetwork.com/"
      >
      </a> */}

        <ResponsiveLayout
          breakpoint={1024}
          renderMobile={<MobileNav />}
          renderDesktop={<NavItems />}
        />
      </div>
    </header>
  );
};

export default Header;
