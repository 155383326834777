import * as React from 'react';

import Hamburger from '../../Hamburger/Hamburger';
import NavItems from '../NavItems/NavItems';

const MobileNav = () => {
  const [showNav, setShowNav] = React.useState(false);

  const onToggleNav = () => {
    setShowNav(prevState => !prevState);
  };

  return (
    <>
      <Hamburger onClick={onToggleNav} />
      {showNav && <NavItems />}
    </>
  );
};

export default MobileNav;
