import React from 'react';
import styles from './NavSubItem.module.scss';
import { Link } from 'react-scroll';

export interface INavSubItem {
  link: string;
  internal?: boolean;
  name: string;
}

const NavSubItem = ({
  name,
  link,
  internal,
}: {
  name: string;
  link: string;
  internal?: boolean;
}) => {
  return (
    <li className={styles.navSubItem}>
      {internal ? (
        <Link className={styles.navSubItem_link} to={`/${link}`}>
          {name}
        </Link>
      ) : (
        <a className={styles.navSubItem_link} href={link}>
          {name}
        </a>
      )}
    </li>
  );
};

export default NavSubItem;
