import React from 'react';
import classnames from 'classnames';
import s from './PlusMinus.module.scss';

const PlusMinus = ({
  onClick,
  showMinus,
}: {
  onClick: () => void;
  showMinus: boolean;
}) => {
  return (
    <>
      <span
        onClick={onClick}
        className={classnames(s.plusMinus, { [s.open]: showMinus })}
      ></span>
    </>
  );
};

export default PlusMinus;
