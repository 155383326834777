import React from 'react';
import NavSubItem from '../NavSubItem/NavSubItem';
import styles from './NavSubItems.module.scss';

const NavSubItems = () => {
  return (
    <ul className={styles.navSubItems}>
      <NavSubItem
        link={
          'https://www.incarcerationnationsnetwork.com/education-not-incarceration/'
        }
        name={'Education not Incarceration'}
      />

      <NavSubItem
        link={'https://www.incarcerationnationsnetwork.com/health-and-humanity/'}
        name={'Health & Humanity'}
      />
      <NavSubItem
        link={'https://www.incarcerationnationsnetwork.com/women-behind-bars/'}
        name={'Woman Behind Bars'}
      />

      <NavSubItem
        link={'https://www.incarcerationnationsnetwork.com/solitary-is-madness/'}
        name={'Solidarity is Madness'}
      />
      <NavSubItem
        link={'https://www.incarcerationnationsnetwork.com/heal-not-harm/'}
        name={'Heal Not Harm'}
      />
    </ul>
  );
};

export default NavSubItems;
