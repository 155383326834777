import React from 'react';

import NavItem from '../NavItem/NavItem';
import NavSubItems from '../NavSubItems/NavSubItems';

import styles from './NavItems.module.scss';
import BluePrintSubItems from '../BlueprintSubItems/BlueprintSubItems';

const NavItems = () => {
  return (
    <div className={styles.navLayout}>
      <ul className={styles.navLayout_list}>
        <NavItem link={'/'} name={'Platform'} internal />
        <NavItem link={'blueprint'} name={'About'} internal>
          <BluePrintSubItems />
        </NavItem>
        <NavItem
          link={'https://www.incarcerationnationsnetwork.com/who/'}
          name={'Who'}
        />
        <NavItem
          link={'https://www.incarcerationnationsnetwork.com/why/'}
          name={'Why'}
        />

        <NavItem
          link={'https://www.incarcerationnationsnetwork.com/how/'}
          name={'How'}
        />

        <NavItem
          link={'https://www.incarcerationnationsnetwork.com/the-issues/'}
          name={'The Issues'}
        >
          <NavSubItems />
        </NavItem>

        <NavItem
          link={'https://www.incarcerationnationsnetwork.com/projects/'}
          name={'Projects'}
        />
        <NavItem
          link={'https://www.incarcerationnationsnetwork.com/contact/'}
          name={'Contact'}
        />
      </ul>
    </div>
  );
};

export default NavItems;
