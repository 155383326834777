import React from 'react';

import useSubNav from '../../../hooks/useSubNav';
import { useWindowDimensions } from '../../../hooks/WindowDimensionsProvider';

import PlusMinus from '../../PlusMinus/PlusMinus';

import styles from './NavItem.module.scss';

import classnames from 'classnames';
import { Link } from 'gatsby';

const NavItem = ({
  link,
  name,
  children,
  internal,
}: {
  link: string;
  name: string;
  internal?: boolean;
  children?: React.ReactNode;
}) => {
  const { width } = useWindowDimensions();
  const { isShowing, toggle } = useSubNav();

  const handleMouseEnterLeave = () => {
    width > 1024 ? toggle() : null;
  };

  return (
    <li
      className={styles.navItem}
      onMouseEnter={handleMouseEnterLeave}
      onMouseLeave={handleMouseEnterLeave}
    >
      {internal ? (
        <Link
          className={classnames(styles.navItem_link, {
            [styles.open]: isShowing,
          })}
          to={`/${link}`}
        >
          {name}
        </Link>
      ) : (
        <a
          className={classnames(styles.navItem_link, {
            [styles.open]: isShowing,
          })}
          href={link}
        >
          {name}
        </a>
      )}
      {isShowing && children}
      {children !== undefined && (
        <PlusMinus onClick={toggle} showMinus={isShowing} />
      )}
    </li>
  );
};

export default NavItem;
