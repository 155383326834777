import React, { ReactNode } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import WindowDimensionsProvider from '../../hooks/WindowDimensionsProvider';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import SEO from '../Seo';

const Layout = ({ children }: { children: ReactNode }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <WindowDimensionsProvider>
      <SEO title={data.site.siteMetadata.title} />
      <Header />
      <div
        style={{
          margin: `0 auto`,
          paddingTop: 0,
          backgroundColor: `white`,
        }}
      >
        <main>{children}</main>
      </div>
      <Footer />
    </WindowDimensionsProvider>
  );
};

export default Layout;
