import * as React from 'react';

import styles from './Hamburger.module.scss';

const Hamburger = ({ onClick }: { onClick: () => void }) => {
  return (
    <>
      <div onClick={onClick} className={styles.hamburger}>
        <span className={styles.hamburgerBars}></span>
      </div>
    </>
  );
};

export default Hamburger;
